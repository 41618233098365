/* =============== Navbar =============== */
.navbar {
  background-color: rgba(11, 11, 13, 0.8); /* Semi-transparent dark background */
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

/* Navbar Title */
.navbar h1 {
  color: #61dafb;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

/* Navbar Links */
.navbar ul {
  list-style-type: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
  position: relative;
  left: 0; /* Remove unnecessary left shift */
  justify-content: center; /* Centers items properly */
  align-items: center;
}



.navbar ul li {
  display: inline;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease-in-out;
}

.navbar ul li a:hover {
  color: #61dafb;
}

/* Ensure the body does not overlap the navbar */
body {
  padding-top: 80px; /* Adjust based on navbar height */
}



/* =============== General Styles =============== */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  text-align: center;
}

/* =============== Hero Section =============== */
.hero {
  padding: 4rem 2rem;
  text-align: center;
}

.hero h1 {
  font-size: 3rem;
}

.hero h2 {
  font-size: 2rem;
  margin-top: 10px;
  color: #ffdfba;
}

.highlight {
  color: #61dafb;
}

.cta-button {
  background: #61dafb;
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: 0.3s;
}

.cta-button:hover {
  background: white;
  color: #61dafb;
}

/* =============== Skills Section =============== */
.skills {
  padding: 3rem 2rem;
  text-align: center;
}

.skills h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.skill {
  background: rgba(255, 255, 255, 0.2);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2rem;
}

/* =============== Projects Section =============== */
.projects {
  padding: 3rem 2rem;
  text-align: center;
}

.projects h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.project-card {
  background: rgba(255, 255, 255, 0.2);
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 10px;
  max-width: 600px;
}

.project-card h3 {
  color: #ffdfba;
}

.project-card a {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
}

.project-card a:hover {
  text-decoration: underline;
}

/* =============== Contact Section =============== */
.contact {
  padding: 3rem 2rem;
}

.contact h2 {
  font-size: 2.5rem;
}

.contact p {
  font-size: 1.2rem;
}

/* =============== Responsive Design Mobile =============== */

/* Adjust Navbar for Smaller Screens */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }

  .navbar h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .navbar ul {
    flex-direction: column;
    gap: 15px;
    padding-top: 10px;
  }

  .navbar ul li {
    width: 100%;
    text-align: center;
  }

  .navbar ul li a {
    display: block;
    font-size: 1rem;
    padding: 10px;
    width: 100%;
  }
}


/* Adjust Hero Section for Mobile */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.2rem;
  }

  .hero h2 {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 8px 15px;
  }
}

/* Adjust Skills Section */
@media (max-width: 768px) {
  .skills-container {
    flex-direction: column;
    gap: 10px;
  }

  .skill {
    font-size: 1rem;
    padding: 8px 15px;
  }
}

/* Adjust Projects Section */
@media (max-width: 768px) {
  .project-card {
    max-width: 90%;
    padding: 15px;
  }

  .project-card h3 {
    font-size: 1.2rem;
  }
}

/* Adjust Sale Page (Product Card) */
@media (max-width: 768px) {
  .product-card {
    width: 90%;
  }

  .product-image {
    max-width: 100%;
  }

  .add-to-cart, .sold-out {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}
