/* Sale Section */
.sale {
    text-align: center;
    padding: 2rem;
  }
  
  /* Product Card */
  .product-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    width: 280px;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
  }
  
  /* Product Image */
  .product-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  /* Button Styling */
  .add-to-cart, .sold-out {
    padding: 10px 15px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .add-to-cart {
    background-color: #61dafb;
    color: black;
  }
  
  .add-to-cart:hover {
    background-color: #45aaf2;
  }
  
  .sold-out {
    background-color: red;
    color: white;
    cursor: not-allowed;
  }
  